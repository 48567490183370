.row {
  margin-left: 20px;
  color: white;
}

.row__poster {
  object-fit: contain;
  width: 100%;
  max-height: 100px;
  transition: transform 450ms;
  margin-right: 10px;
}
.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
}

.row__posters::-webkit-scrollbar {
  display: none;
}

.row__poster:hover {
  transform: scale(1.08);
}

.row__posterLarge {
  max-height: 250px;
}

.row__posterLarge:hover {
  transform: scale(1.09);
}
